<template>
  <div>
    <v-card>
      <v-card-title>
        Poptávky
        <v-spacer></v-spacer>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="demands"
        class="elevation-1"
        :server-items-length="totalDemands"
        :options.sync="paginationOptions"
        :footer-props="{'items-per-page-options': [10, 20, 50] }"
      >
        <template v-slot:item.hostName="{ item }">
          <a :href="`/admin/hosts/${item.property.owner.id}`" class="table-link">
            {{ item.property.owner.firstName }} {{ item.property.owner.lastName }}
          </a>
        </template>
        <template v-slot:item.firm="{ item }">
          <v-chip
            :color="getColor(item)"
            dark
          >
            {{ getFirmText(item) }}
          </v-chip>
        </template>
        <template v-slot:item.propertyName="{ item }">
          <a :href="`/admin/properties/${item.property.id}`" class="table-link">
            {{ item.property.name }}
          </a>
        </template>
        <template v-slot:item.dateFrom="{ item }">
          {{ item.dateFrom | localizedDate }}
        </template>
        <template v-slot:item.dateTo="{ item }">
          {{ getLocalizedDate(item.dateTo) }}
        </template>
        <template v-slot:item.createdAt="{ item }">
          {{ getLocalizedDate(item.createdAt) }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="editDemand(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            small
            @click="deleteDialog = true"
            class="mr-2"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      width="450"
      v-model="editDialog">
      <v-card>
        <v-card-title>Změnit stav</v-card-title>
        <v-card-text class="pb-5">
          <DemandForm
            v-if="editDialog"
            :demand="editedItem"
            @edit-success="showSuccess"></DemandForm>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      width="350"
      v-model="deleteDialog">
      <v-card>
        <v-card-title class="text-center">Opravdu smazat?</v-card-title>
        <v-card-actions class="d-flex justify-center ">
          <v-btn
            width="250"
            color="red"
            class="rounded-0">
            Smazat
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DemandForm from '../demands/DemandForm'
import demandsOverviewHelpers from '../../mixins/local/demandsOverviewHelpers'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'DemandsOverview',
  components: { DemandForm },
  mixins: [demandsOverviewHelpers],
  data () {
    return {
      editDialog: false,
      editedItem: {},
      deleteDialog: false,
      headers: [
        { text: 'Číslo poptávky', value: 'id' },
        { text: 'Hostitel', value: 'hostName' },
        { text: 'Typ', value: 'firm' },
        { text: 'Objekt', value: 'propertyName' },
        { text: 'Vytvořeno', value: 'createdAt' },
        { text: 'Datum od', value: 'dateFrom' },
        { text: 'Datum do', value: 'dateTo' },
        { text: 'Akce', value: 'actions' }
      ],
      runningRequest: false,
      demands: [],
      totalDemands: 0
    }
  },
  methods: {
    editDemand (demand) {
      this.editDialog = true
      this.editedItem = { ...demand }
    },
    showSuccess () {
      this.setAlerts([{ type: 'success', message: 'Editace proběhla úspěšně' }])
      this.editDialog = false
      this.getDemands()
    },
    getDemands () {
      this.runningRequest = true
      const { sortBy, sortDesc, page, itemsPerPage } = this.paginationOptions
      this.$http.get(`/users/${this.user.id}/demands`, {
        params: {
          sortBy: sortBy[0] || null,
          sortDesc: sortDesc[0] || null,
          page,
          itemsPerPage
        }
      })
        .then(res => {
          this.demands = res.data.demands
          this.totalDemands = +res.data.total
        })
        .finally(() => {
          this.runningRequest = false
        })
    },
    ...mapActions({
      setAlerts: 'alerts/setAlerts'
    })
  },
  computed: {
    ...mapGetters({
      user: 'detailViews/user'
    })
  },
  mounted () {
    this.loadDemands = this.debounce(this.getDemands, 1500)
    this.getDemands()
  }
}
</script>

<style scoped>

</style>
