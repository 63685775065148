<template>
    <v-container class="px-5" v-if="!loading">
      <div class="display-1 text-center">Detail uživatele</div>
      <v-col cols="12" class="d-flex justify-end">
        <v-switch
          v-if="!user.deletedAt"
          v-model="editEnabled"
          label="Povolit editaci"
          dense
        >
        </v-switch>
      </v-col>
      <v-form v-model="formValid" ref="form" :disabled="!editEnabled">
        <v-row class="mt-4">
          <v-col cols="6">
            <v-text-field
              v-model="form.email"
              type="email"
              label="Email*"
              :rules="[validation.required, validation.email, validation.maxLength(255)]"
              outlined
              dense
              class="rounded-0"
              required>
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="form.nickname"
              :rules="[validation.required, validation.maxLength(255)]"
              type="text"
              label="Přezdívka*"
              outlined
              dense
              class="rounded-0"
              required>
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="form.password"
              :rules="[validation.minLength(6), validation.equal(form.passwordConfirmation)]"
              type="password"
              label="Heslo*"
              outlined
              dense
              class="rounded-0"
              required>
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="form.passwordConfirmation"
              type="password"
              label="Heslo znovu*"
              :rules="[ validation.equal(form.password)]"
              outlined
              dense
              class="rounded-0"
              required>
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model.trim="form.firstName"
              :rules="[validation.all([form.firstName, form.lastName]), validation.maxLength(255)]"
              type="text"
              label="Jméno"
              outlined
              dense
              class="rounded-0"
            >
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model.trim="form.lastName"
              :rules="[validation.all([form.firstName, form.lastName]), validation.maxLength(255)]"
              type="text"
              label="Příjmení"
              outlined
              dense
              class="rounded-0"
            >
            </v-text-field>
          </v-col>
          <v-col cols="8">
            <v-text-field
              v-model.trim="form.street"
              type="text"
              :rules="[validation.maxLength(255)]"
              label="Adresa"
              outlined
              class="rounded-0"
              hide-details>
            </v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model.trim="form.streetNumber"
              :rules="[ /* validation.all([form.street, form.streetNumber, form.city, form.zip]) */validation.maxLength(255)]"
              type="text"
              label="Číslo popisné"
              outlined
              class="rounded-0"
              hide-details>
            </v-text-field>
          </v-col>
          <v-col cols="8">
            <v-text-field
              v-model.trim="form.city"
              type="text"
              :rules="[validation.maxLength(255)]"
              label="Město"
              outlined
              class="rounded-0"
              hide-details>
            </v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model.trim="form.zip"
              :rules="[validation.maxLength(255)]"
              type="text"
              label="PSČ"
              outlined
              class="rounded-0"
              hide-details>
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model.trim="form.phone"
              :rules="[validation.maxLength(255)]"
              type="tel"
              label="Telefon"
              outlined
              class="rounded-0"
              hide-details>
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model.trim="form.phone2"
              :rules="[validation.maxLength(255)]"
              type="tel"
              label="Druhý telefon"
              outlined
              dense
              class="rounded-0"
              hide-details>
            </v-text-field>
          </v-col>
          <v-col class="d-flex justify-end">
            <v-btn
              color="primary"
              class="rounded-0"
              :disabled="!formValid || !editEnabled"
              @click="save"
              v-if="!user.deletedAt"
            >
              Uložit
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
    <v-skeleton-loader v-else type="article" />
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'EditUser',
  data () {
    return {
      editEnabled: false,
      form: {
        email: '',
        nickname: '',
        password: '',
        passwordConfirmation: '',
        firstName: '',
        lastName: '',
        street: '',
        streetNumber: '',
        city: '',
        zip: ''
      },
      runningRequest: false,
      formValid: true
    }
  },
  methods: {
    // TODO: backend
    save () {
      this.runningRequest = true
      this.$http.put(`/admin/users/${this.user.id}`, {
        ...this.form,
        password: this.form.password || null,
        passwordConfirmation: this.form.passwordConfirmation || null
      })
        .then(res => {
          this.setUser(res.data)
          this.setAlerts([{
            message: 'Úprava zákazníka proběhla úspěšně',
            type: 'success'
          }])
          this.editEnabled = false
        })
        .catch(err => {
          if (err.response.data.message === 'EMAIL_ALREADY_USED') {
            this.setAlerts([{ message: 'E-mail je již používán', type: 'error' }])
          }
        })
        .finally(() => {
          this.runningRequest = false
        })
    },
    ...mapMutations({
      setUser: 'detailViews/setUser'
    }),
    ...mapActions({
      setAlerts: 'alerts/setAlerts'
    })
  },
  computed: {
    loading () {
      return !Object.keys(this.user).length || this.runningRequest
    },
    ...mapGetters({
      user: 'detailViews/user'
    })
  },
  watch: {
    user () {
      this.form = {
        ...this.user,
        password: '',
        passwordConfirmation: ''
      }

      delete this.form.id
    }
  }
}
</script>

<style scoped>

</style>
