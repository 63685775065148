var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_vm._v(" Poptávky "),_c('v-spacer')],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.demands,"server-items-length":_vm.totalDemands,"options":_vm.paginationOptions,"footer-props":{'items-per-page-options': [10, 20, 50] }},on:{"update:options":function($event){_vm.paginationOptions=$event}},scopedSlots:_vm._u([{key:"item.hostName",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"table-link",attrs:{"href":("/admin/hosts/" + (item.property.owner.id))}},[_vm._v(" "+_vm._s(item.property.owner.firstName)+" "+_vm._s(item.property.owner.lastName)+" ")])]}},{key:"item.firm",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item),"dark":""}},[_vm._v(" "+_vm._s(_vm.getFirmText(item))+" ")])]}},{key:"item.propertyName",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"table-link",attrs:{"href":("/admin/properties/" + (item.property.id))}},[_vm._v(" "+_vm._s(item.property.name)+" ")])]}},{key:"item.dateFrom",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("localizedDate")(item.dateFrom))+" ")]}},{key:"item.dateTo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getLocalizedDate(item.dateTo))+" ")]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getLocalizedDate(item.createdAt))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editDemand(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){_vm.deleteDialog = true}}},[_vm._v(" mdi-delete ")])]}}])})],1),_c('v-dialog',{attrs:{"width":"450"},model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Změnit stav")]),_c('v-card-text',{staticClass:"pb-5"},[(_vm.editDialog)?_c('DemandForm',{attrs:{"demand":_vm.editedItem},on:{"edit-success":_vm.showSuccess}}):_vm._e()],1)],1)],1),_c('v-dialog',{attrs:{"width":"350"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-center"},[_vm._v("Opravdu smazat?")]),_c('v-card-actions',{staticClass:"d-flex justify-center "},[_c('v-btn',{staticClass:"rounded-0",attrs:{"width":"250","color":"red"}},[_vm._v(" Smazat ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }