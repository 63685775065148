<template>
  <div>
    <v-card>
      <v-card-title>
        Rezervace
        <v-spacer></v-spacer>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="reservations"
        class="elevation-1"
        :loading="runningRequest"
        :server-items-length="reservationsTotal"
        :options.sync="paginationOptions"
        :footer-props="{'items-per-page-options': [10, 20, 50] }"
      >
        <template v-slot:item.property="{ item }">
          <a href="#" class="table-link">
            {{ item.property }}
          </a>
        </template>
        <template v-slot:item.hostName="{ item }">
          <a :href="`/admin/hosts/${item.property.owner.id}`" class="table-link">
            {{ item.property.owner.firstName }} {{ item.property.owner.lastName }}
          </a>
        </template>
        <template v-slot:item.propertyName="{ item }">
          <a :href="`/admin/properties/${item.property.id}`" class="table-link">
            {{ item.property.name }}
          </a>
        </template>
        <template v-slot:item.dateFrom="{ item }">
          {{ getLocalizedDate(item.dateFrom) }}
        </template>
        <template v-slot:item.dateTo="{ item }">
          {{ getLocalizedDate(item.dateTo) }}
        </template>
        <template v-slot:item.createdAt="{ item }">
          {{ getLocalizedDate(item.createdAt) }}
        </template>
        <template v-slot:item.rating="{ item }">
            {{ item.reviewed ? 'Ano' : 'Ne' }}
        </template>
        <template v-slot:item.actions="{ /* item */ }">
          <v-icon
            small
            @click="deleteDialog = true"
            class="mr-2"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      width="450"
      v-model="editDialog">
      <v-card>
        <v-card-title>Změnit stav</v-card-title>
        <v-card-text class="pb-0">
          <v-select
            :items="['Zavazna', 'Nezavazna']"
            label="stav"
            class="rounded-0"
            outlined>
          </v-select>
        </v-card-text>
        <v-card-actions class="d-flex justify-end pt-0">
          <v-btn
            color="primary"
            class="rounded-0">
            Uložit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      width="350"
      v-model="deleteDialog">
      <v-card>
        <v-card-title class="text-center">Opravdu smazat?</v-card-title>
        <v-card-actions class="d-flex justify-center ">
          <v-btn
            width="250"
            color="red"
            class="rounded-0">
            Smazat
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ReservationsOverview',
  data () {
    return {
      editDialog: false,
      deleteDialog: false,
      headers: [
        { text: 'Číslo rezervace', value: 'id' },
        { text: 'Hostitel', value: 'hostName' },
        { text: 'Objekt', value: 'propertyName' },
        { text: 'Vytvořeno', value: 'createdAt' },
        { text: 'Datum od', value: 'dateFrom' },
        { text: 'Datum do', value: 'dateTo' },
        { text: 'Recenze', value: 'rating', sortable: false },
        { text: 'Akce', value: 'actions' }
      ],
      runningRequest: false,
      reservations: [],
      paginationOptions: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: []
      },
      reservationsTotal: 0
    }
  },
  methods: {
    getReservations () {
      this.runningRequest = true
      const { sortBy, sortDesc, page, itemsPerPage } = this.paginationOptions

      this.$http.get(`/users/${this.user.id}/reservations`, {
        params: {
          sortBy: sortBy.length ? sortBy[0] : null,
          sortDesc: sortDesc.length ? sortDesc[0] : null,
          page,
          itemsPerPage
        }
      })
        .then(res => {
          this.reservations = res.data.demands
          this.reservationsTotal = +res.data.total
        })
        .finally(() => {
          this.runningRequest = false
        })
    }
  },
  computed: {
    ...mapGetters({
      user: 'detailViews/user'
    })
  },
  watch: {
    paginationOptions: {
      deep: true,
      handler () {
        this.loadReservations()
      }
    }
  },
  mounted () {
    this.getReservations()
    this.loadReservations = this.debounce(this.getReservations, 2000)
  }
}
</script>

<style scoped>

</style>
