<template>
  <div>
    <v-tabs v-model="tab">
      <v-tab>
        Informace
      </v-tab>
      <v-tab>
        Poptávky
      </v-tab>
      <v-tab>
        Rezervace
      </v-tab>
      <v-tab>
        Recenze
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <EditUser></EditUser>
      </v-tab-item>
      <v-tab-item>
        <DemandsOverview></DemandsOverview>
      </v-tab-item>
      <v-tab-item>
        <ReservationsOverview></ReservationsOverview>
      </v-tab-item>
      <v-tab-item>
        <ReviewsOverview type="user"></ReviewsOverview>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import EditUser from '../../components/users/EditUser'
import DemandsOverview from '../../components/users/DemandsOverview'
import ReservationsOverview from '../../components/users/ReservationsOverview'
import ReviewsOverview from '../../components/users/ReviewsOverview'
import { mapActions, mapMutations } from 'vuex'
export default {
  name: 'UserDetail',
  components: { ReviewsOverview, ReservationsOverview, DemandsOverview, EditUser },
  data () {
    return {
      tab: 0
    }
  },
  methods: {
    ...mapMutations({
      setUser: 'detailViews/setUser'
    }),
    ...mapActions({
      setAlerts: 'alerts/setAlerts'
    })
  },
  created () {
    this.$http.get(`/users/${this.$route.params.id}`)
      .then(res => {
        this.setUser(res.data)
      })
      .catch((err) => {
        if (err.response.status === 404) {
          this.setAlerts([
            { message: 'Uživatel nenalezen', type: 'error' }
          ])
        }

        if (err.response.data.message === 'NOT_A_CUSTOMER') {
          this.setAlerts([])
          this.$router.replace({
            name: 'admin.hosts.host',
            params: {
              id: this.$route.params.id
            }
          })
        }
      })
  },
  beforeDestroy () {
    this.setUser({})
  }
}
</script>

<style scoped>

</style>
